<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h2 class="main-tab__title active">Заявки</h2>
    </div>

    <ApplicationsTable />
  </div>
</template>

<script>
import ApplicationsTable from "@/components/applications/ApplicationsTable.vue";

export default {
  name: "applications-page",
  components: {
    ApplicationsTable,
  },
  setup() {
    return {};
  },
};
</script>
