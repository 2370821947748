<template>
  <section class="mb-4 w-100">
    <div class="d-flex align-items-start">
      <div v-if="!isDashboard && !isSupervisor" class="depot__filters-wrapper">
        <SearchFilter v-model="state.searchFilter" />

        <div class="depot__filters">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4 class="depot__filters-title">Фильтр</h4>
            <button
              type="button"
              class="depot__filters-reset"
              @mousedown="resetFilters"
            >
              Очистить все
            </button>
          </div>

          <span class="line-separator"></span>

          <h5 class="depot__filters-subtitle">Статус</h5>
          <v-select
            class="depot__select"
            placeholder="Все статусы"
            label="title"
            :options="state.applicationStatuses"
            :reduce="(item) => item.id"
            v-model="state.statusFilter"
          ></v-select>
        </div>
      </div>

      <FixedColumnTable
        :class="{ 'application-worker': isDashboard || isSupervisor }"
      >
        <table class="table main-table bordered text-center">
          <thead>
            <tr class="text-nowrap">
              <th scope="col">№</th>
              <th class="text-start" scope="col">Наименование</th>
              <th scope="col">Сформирован</th>
              <th scope="col">Стадия</th>
              <th scope="col">Приоритет</th>
              <th scope="col">Статус</th>
              <th scope="col">Прогресс</th>
              <th scope="col">Время выполнения</th>
              <!-- <th scope="col">Редактировать</th> -->
              <th scope="col">Начать</th>
              <th scope="col">План</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in state.applications" :key="item.id">
              <td :style="{ width: item.id.length }">{{ item.id }}</td>
              <td class="text-start" style="min-width: 300px">
                <router-link
                  class="main-link"
                  :to="{
                    name:
                      item.status.id != 3
                        ? 'formation-page'
                        : 'application-plan-page',
                    params: { id: parseInt(item.id) },
                  }"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Нажмите чтоб узнать данные о заказе"
                  @click="hideTooltip"
                  >{{ item.title }}</router-link
                >
                <span v-if="item.bitrix_id == 101" class="d-block text-success"
                  >*Не привязан к заявке</span
                >
              </td>
              <td>
                <span v-if="item.is_formed" class="text-success">Да</span>
                <span v-else class="text-danger">Нет</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-gold btn-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#stage-modal"
                  @click="state.taskStageToShow = item.id"
                  :disabled="!item.is_formed"
                >
                  <inline-svg
                    width="24"
                    height="24"
                    :src="require('@/assets/images/info.svg')"
                  />
                </button>
              </td>
              <td>
                <span v-if="item.priority == 60">&#9889;&#9889;&#9889;</span>
                <span v-else-if="item.priority == 58">&#9889;&#9889;</span>
                <span v-else-if="item.priority == 56">&#9889;</span>
                <span v-else>-</span>
              </td>
              <td>
                <span
                  class="main-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  :title="item.status?.id ? item.status?.title : 'Не указан'"
                  :class="
                    item.status?.id
                      ? getClassForStatus(item.status?.id)
                      : 'danger'
                  "
                ></span>
              </td>
              <td>
                <span
                  class="text-nowrap"
                  :class="{
                    'text-danger': item.progress == 0,
                    'text-warning': item.progress > 0 && item.progress < 100,
                    'text-success': item.progress == 100,
                  }"
                  >{{ item.progress ? item.progress + "%" : "0%" }}</span
                >
              </td>
              <td>
                <span class="text-nowrap">{{ item.total_time ?? "-" }}</span>
              </td>
              <!-- <td>
                <button
                  type="button"
                  class="btn btn-outline-gold btn-icon"
                  :data-bs-toggle="!isAdmin ? 'tooltip' : 'modal'"
                  data-bs-placement="bottom"
                  data-bs-target="#edit-order-modal"
                  :title="
                    !isAdmin
                      ? 'ВНИМАНИЕ! Редактировать может только администратор'
                      : ''
                  "
                  :disabled="!isAdmin"
                  @click="state.orderToUpdate = item"
                >
                  <inline-svg :src="require('@/assets/images/edit-1.svg')" />
                </button>
              </td> -->
              <td>
                <router-link
                  :to="{
                    name: 'formation-page',
                    params: { id: parseInt(item.id) },
                  }"
                  class="btn btn-gold w-100"
                  v-if="item.status.id != 3"
                >
                  Перейти
                </router-link>
                <button type="button" class="btn btn-disabled w-100" v-else>
                  Отправлено
                </button>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'application-plan-page',
                    params: { id: parseInt(item.id) },
                  }"
                  class="btn btn-gold w-100"
                >
                  Перейти
                </router-link>
              </td>
            </tr>

            <tr v-show="!state.applications.length && !isDataLoading">
              <td colspan="7">
                <span class="d-block text-bold my-3">Ничего не найдено!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </FixedColumnTable>
    </div>
    <Pagination
      v-model:currentPage="state.currentPage"
      :totalPages="state.totalPages"
    />
  </section>

  <EditOrderModal
    :entityToUpdate="state.orderToUpdate"
    :apiRequest="editDeal"
    :refetch="getData"
    :applicationStatuses="state.applicationStatuses"
  />
  <StageModal :taskId="state.taskStageToShow" :apiRequest="getTaskStage" />
</template>

<script>
import { reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";

import { hideTooltip } from "@/helpers/bootstrap";
import useLoadingModal from "@/mixins/useLoadingModal";
import { errorAlert } from "@/helpers/alerts";
import { formatDateTime } from "@/helpers/dateTimeUtils";
import { getClassForStatus } from "@/helpers/applicationStatus";
import {
  getDeals as fetchDeals,
  getDealStatuses,
  editDeal,
  getTaskStage,
} from "@/api/deal";

import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";
import EditOrderModal from "@/components/applications/EditOrderModal.vue";
import Pagination from "@/components/ui/Pagination.vue";
import SearchFilter from "@/components/depot/SearchFilter.vue";
import StageModal from "@/components/applications/StageModal.vue";

export default {
  name: "applications-page",
  components: {
    FixedColumnTable,
    EditOrderModal,
    Pagination,
    SearchFilter,
    StageModal,
  },
  props: ["isDashboard", "isSupervisor"],
  setup(props) {
    const store = useStore();
    const isAdmin = computed(() => store.getters.isAdmin);
    const state = reactive({
      loading: false,
      searchFilter: null,
      statusFilter: props.isSupervisor ? 1 : null,
      currentPage: 1,
      totalPages: 1,
      orderToUpdate: null,
      applications: [],
      applicationStatuses: [],
      taskStageToShow: null,
    });

    const { isDataLoading } = useLoadingModal();

    async function getData() {
      isDataLoading.value = true;

      try {
        const res = await fetchDeals(
          state.currentPage,
          state.searchFilter,
          state.statusFilter
        );
        state.applications = res.data;
        state.totalPages = res.meta?.last_page;
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
      }
    }

    watch(
      () => [state.statusFilter, state.searchFilter],
      () => {
        if (state.currentPage != 1) state.currentPage = 1;
        else getData();
      }
    );

    watch(
      () => state.currentPage,
      () => {
        getData();
      }
    );

    onMounted(async () => {
      getData();
      state.applicationStatuses = await getDealStatuses();
    });

    const resetFilters = () => {
      state.statusFilter = null;
      state.searchFilter = null;
    };

    return {
      state,
      isAdmin,
      hideTooltip,
      formatDateTime,
      getClassForStatus,
      getData,
      isDataLoading,
      editDeal,
      resetFilters,
      getTaskStage,
    };
  },
};
</script>

<style>
.application-worker .awesome-table {
  max-height: calc(100vh - 330px);
}
</style>