<template>
  <div
    id="edit-order-modal"
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 555px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              Редактировать заказ
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>

          <div class="main-small-alert mt-2">
            <inline-svg
              class="main-small-alert__icon"
              :src="require('@/assets/images/alert.svg')"
              fill="#717C99"
            />

            <p class="main-small-alert__text">
              Пункты помеченные символом <span class="text-danger">*</span>,
              обязательны для проверки/заполнения.
            </p>
          </div>
        </div>

        <div class="modal-body">
          <div>
            <input class="visually-hidden" type="text" />

            <div class="main-form-control mb-3">
              <label class="main-form-control__label">
                Наименование: <span class="text-danger">*</span>
              </label>

              <div class="main-form-control__input-wrapper">
                <input
                  class="main-form-control__input"
                  type="text"
                  placeholder="Наименование"
                  v-model="state.title"
                  disabled
                />
                <div
                  class="main-form-control__input-svg-wrapper"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Система не разрешает менять название"
                >
                  <inline-svg
                    :src="require('@/assets/images/lock.svg')"
                    fill="#717C99"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="main-form-control__label">
                Статус: <span class="text-danger">*</span>
              </label>

              <v-select
                class="main-form-control__select dark"
                :class="{ error: v$.status.$error }"
                placeholder="Установить статус"
                v-model="state.status"
                :options="applicationStatuses"
                label="title"
                :reduce="(item) => item"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    class="main-circle me-2"
                    :class="getClassForStatus(option.id)"
                  ></span>
                  {{ option.title }}
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.status.$error"
                >* Данное поле обязательно</span
              >
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="d-flex w-100">
            <button
              type="button"
              class="btn btn-outline-white btn-md w-50 me-3"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md w-50 ms-3"
              :disabled="state.isFormLoading"
            >
              <span>Сохранить</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import useModal from "@/mixins/useModal";
import { required } from "@vuelidate/validators";
import { getClassForStatus } from "@/helpers/applicationStatus";

export default {
  props: ["entityToUpdate", "apiRequest", "refetch", "applicationStatuses"],
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      title: "",
      status: null,
    });
    const rules = {
      status: { required },
    };

    const { modalRef, v$, onSubmitForm, onResetForm } = useModal(props, {
      state,
      rules,
    });

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
      getClassForStatus,
    };
  },
};
</script>