<template>
  <div
    id="stage-modal"
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 555px">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              Стадии производства
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>
        </div>

        <div
          v-if="!state.isDataLoading"
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
        >
          <ol class="list-group list-group-numbered">
            <li
              class="list-group-item"
              v-for="task in state.tasks"
              :key="task.id"
            >
              {{ task.product?.title }} -
              <span
                :class="{
                  'text-warning': task.is_taken,
                  'text-success': task.is_formed,
                  'text-danger': !task.is_formed && !task.is_taken,
                }"
                >{{
                  task.is_taken
                    ? "В работе"
                    : task.is_formed
                    ? "Выполнен"
                    : "К выполнению"
                }}</span
              >

              <ol
                class="list-group list-group-numbered"
                v-for="group in task.subtasks"
                :key="group.id"
              >
                <li class="list-group-item border-0">
                  {{ group.product?.title }} -
                  <span
                    :class="{
                      'text-warning': group.is_taken,
                      'text-success': group.is_formed,
                      'text-danger': !group.is_formed && !group.is_taken,
                    }"
                    >{{
                      group.is_taken
                        ? "В работе"
                        : group.is_formed
                        ? "Выполнен"
                        : "К выполнению"
                    }}</span
                  >

                  <ol
                    class="list-group list-group-numbered"
                    v-for="subtask in group.subtasks"
                    :key="subtask.id"
                  >
                    <li class="list-group-item border-0">
                      {{ subtask.product?.title }} -
                      <span
                        :class="{
                          'text-warning': subtask.is_taken,
                          'text-success': subtask.is_formed,
                          'text-danger':
                            !subtask.is_formed && !subtask.is_taken,
                        }"
                        >{{
                          subtask.is_taken
                            ? "В работе"
                            : subtask.is_formed
                            ? "Выполнен"
                            : "К выполнению"
                        }}</span
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>

          <span
            v-if="!state.tasks?.length"
            class="d-block text-bold text-center my-3"
            >Ничего не найдено!</span
          >
        </div>

        <div
          v-else
          class="d-flex justify-content-center align-items-center my-5"
        >
          <div class="spinner-border spinner-border-md ms-2" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRef, watch } from "vue";

import { errorAlert } from "@/helpers/alerts";

export default {
  props: ["taskId", "apiRequest", "title"],
  emits: ["update:taskId"],
  setup(props) {
    const taskId = toRef(props, "taskId");
    const state = reactive({
      isDataLoading: false,
      tasks: [],
    });

    watch(taskId, async () => {
      if (taskId.value == null || !props.apiRequest) return;

      try {
        state.isDataLoading = true;
        const res = await props.apiRequest(taskId.value);

        if (res.progress.error) {
          state.tasks = [];

          return;
        }

        state.tasks = res.progress;
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        state.isDataLoading = false;
      }
    });

    return {
      state,
    };
  },
};
</script>